import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [];

export const dictionary = {
		"/": [6],
		"/(authenticated)/account": [7,[2]],
		"/(authenticated)/account/[tab]": [8,[2]],
		"/confirm": [21],
		"/(authenticated)/dashboard": [9,[2]],
		"/(authenticated)/experimental": [10,[2,3]],
		"/(authenticated)/experimental/agents": [11,[2,3]],
		"/(authenticated)/experimental/agents/[tab]": [12,[2,3]],
		"/(authenticated)/experimental/hewsync": [13,[2,3]],
		"/forgot-password": [22],
		"/iframe": [23],
		"/login-as": [24],
		"/logout": [25],
		"/reset-password": [26],
		"/share": [27,[],[5]],
		"/sign-up": [28],
		"/(authenticated)/[organization=organization]": [14,[2]],
		"/(authenticated)/[organization=organization]/api": [15,[2,4]],
		"/(authenticated)/[organization=organization]/api/[type]/[id]/[[tab]]": [16,[2,4]],
		"/(authenticated)/[organization=organization]/dashboard": [17,[2]],
		"/(authenticated)/[organization=organization]/project/[id]/[...page]": [18,[2]],
		"/(authenticated)/[organization=organization]/[slug=perms]": [19,[2]],
		"/(authenticated)/[organization=organization]/[slug=perms]/[id]/[[tab]]": [20,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';